import Empty from "@/components/Empty";
import Loader from "@/components/Loader";
import {
  AlbumTypeEnum,
  OrderStatusEnum,
  TokenSourceEnumMaps,
  TokenStatusEnum,
  TokenStatusEnumMaps,
} from "@/enums";
import assetModel from "@/models/asset.model";
import globalModel from "@/models/global.model";
import orderModel from "@/models/order.model";
import { hideString } from "@/utils/string";
import { useInfiniteScroll, useReactive } from "ahooks";
import KeepAlive from "keepalive-for-react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useCopyToClipboard } from "react-use";
import {
  Image,
  List,
  Popup,
  PullRefresh,
  Sticky,
  Tabs,
  Toast,
} from "react-vant";
import { useSnapshot } from "valtio";
import styles from "./index.module.css";

const HoldListView = ({
  id,
  onClick,
}: {
  id: number;
  onClick?: (item: any) => void;
}) => {
  const { data, loading, loadMoreAsync, reloadAsync, noMore } =
    useInfiniteScroll(
      (d) => {
        return assetModel
          .getTokens({
            id,
            params: {
              current: d ? d?.current + 1 : 1,
              pageSize: 20,
            },
          })
          .then((res) => res?.data);
      },
      {
        reloadDeps: [id],
        isNoMore: (d) => (d ? !d?.hasMore : true),
      }
    );
  return (
    <PullRefresh onRefresh={reloadAsync}>
      {loading ? (
        <Loader />
      ) : !data || data?.list?.length <= 0 ? (
        <Empty />
      ) : (
        <List
          className={styles.list}
          finished={noMore}
          finishedText="我是有底线的"
          onLoad={async () => {
            await loadMoreAsync();
          }}
        >
          {data?.list?.map((item) => (
            <div
              key={item.id}
              className={styles.item}
              onClick={() => onClick && onClick(item)}
            >
              <div
                className={`${styles.status} ${
                  item.status === TokenStatusEnum.LOCKING
                    ? styles.locking
                    : item.status === TokenStatusEnum.ON_SALE
                    ? styles.sale
                    : styles.hold
                }`}
              >
                {TokenStatusEnumMaps[item.status]}
              </div>
              <div className={styles.no}>#{item.no}</div>
              <div className={styles.source}>
                {TokenSourceEnumMaps[item.source]}
              </div>
              <div className={styles.price}>
                购买价 ¥{item.bidPrice ?? "0.00"}
              </div>
              <div className={styles.price}>寄售价 ¥{item.price ?? "0.00"}</div>
            </div>
          ))}
        </List>
      )}
    </PullRefresh>
  );
};

const SoldListView = ({
  type,
  onClick,
}: {
  type: AlbumTypeEnum;
  onClick?: (item: any) => void;
}) => {
  const { data, loading, loadMoreAsync, reloadAsync, noMore } =
    useInfiniteScroll(
      (d) => {
        return orderModel
          .list({
            params: {
              status: OrderStatusEnum.SOLD,
              current: d ? d?.current + 1 : 1,
              pageSize: 20,
            },
          })
          .then((res) => res?.data);
      },
      {
        reloadDeps: [type],
        isNoMore: (d) => (d ? !d?.hasMore : true),
      }
    );
  return (
    <PullRefresh onRefresh={reloadAsync}>
      {loading ? (
        <Loader />
      ) : !data || data?.list?.length <= 0 ? (
        <Empty />
      ) : (
        <List
          className={styles.list}
          finished={noMore}
          finishedText="我是有底线的"
          onLoad={async () => {
            await loadMoreAsync();
          }}
        >
          {data?.list?.map((item: any) => (
            <div key={item.id} className={styles.item}>
              <Image className={styles.image} src={item.assetImage} />
              <div className={styles.title}>{item.assetTitle}</div>
              <div className={styles.num}>
                售出价格: ¥{item.total ?? "0.00"}
              </div>
            </div>
          ))}
        </List>
      )}
    </PullRefresh>
  );
};

const ListView = ({
  type,
  onClick,
}: {
  type: AlbumTypeEnum;
  onClick?: (item: any) => void;
}) => {
  const { data, loading, loadMoreAsync, reloadAsync, noMore } =
    useInfiniteScroll(
      (d) => {
        return assetModel
          .list({
            type,
            status: TokenStatusEnum.HOLD,
            params: {
              current: d ? d?.current + 1 : 1,
              pageSize: 20,
            },
          })
          .then((res) => res?.data);
      },
      {
        reloadDeps: [type],
        isNoMore: (d) => (d ? !d?.hasMore : true),
      }
    );
  return (
    <PullRefresh onRefresh={reloadAsync}>
      {loading ? (
        <Loader />
      ) : !data || data?.list?.length <= 0 ? (
        <Empty />
      ) : (
        <List
          className={styles.list}
          finished={noMore}
          finishedText="我是有底线的"
          onLoad={async () => {
            await loadMoreAsync();
          }}
        >
          {data?.list?.map((item: any) => (
            <div
              key={item.id}
              className={styles.item}
              onClick={() => onClick && onClick(item)}
            >
              <Image className={styles.image} src={item.image} />
              <div className={styles.title}>{item.title}</div>
              <div className={styles.num}>持有数: {item.num}</div>
            </div>
          ))}
        </List>
      )}
    </PullRefresh>
  );
};

export default () => {
  const nav = useNavigate();
  const { user } = useSnapshot(globalModel.state);
  const [_, copy] = useCopyToClipboard();
  const state = useReactive<any>({
    tab: AlbumTypeEnum.ASSET,
    target: null,
  });

  const tabs = useMemo(() => {
    return [
      {
        key: AlbumTypeEnum.ASSET,
        title: "我的资产",
        component: ListView,
        cache: true,
      },
      {
        key: AlbumTypeEnum.BLIND_BOX,
        title: "我的盲盒",
        component: ListView,
        cache: true,
      },
      {
        key: AlbumTypeEnum.SOLD,
        title: "已售出",
        component: SoldListView,
        cache: true,
      },
    ];
  }, []);

  const page = useMemo(() => {
    return tabs.find((tab) => tab.key == state.tab);
  }, [tabs, state.tab]);

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <Image
          className={styles.icon}
          src={require("@/assets/icons/setting.png").default}
          onClick={() => nav("/setting/detail")}
        />
      </div>
      <div className={styles.container}>
        <div className={styles.user} onClick={() => nav("/setting/detail")}>
          <Image
            className={styles.avatar}
            src={
              user?.avatar ?? require("@/assets/images/logo-dark.png").default
            }
          />
          <div className={styles.info}>
            <div className={styles.name}>
              {user?.nickname}
              {user?.isReal && (
                <Image
                  className={styles.isReal}
                  src={require("@/assets/images/mine/realname.png").default}
                />
              )}
            </div>
            <div className={styles.id}>ID: {user?.id}</div>
          </div>
        </div>
        <div
          className={styles.account}
          onClick={(e) => {
            e.stopPropagation();
            copy(user?.account);
            Toast("复制成功");
          }}
        >
          区块链地址: {hideString(user?.account, 16, 8, "*")}
          <span>| 复制地址</span>
        </div>
        <div className={styles.actions}>
          <div className={styles.item} onClick={() => nav("/wallet/detail")}>
            <Image className={styles.image} src={"/images/mine/action-1.png"} />
            <div className={styles.text}>钱包</div>
          </div>
          <div className={styles.item} onClick={() => nav("/order")}>
            <Image className={styles.image} src={"/images/mine/action-2.png"} />
            <div className={styles.text}>订单</div>
          </div>
          <div
            className={styles.item}
            onClick={async () => {
              Toast("敬请期待");
              return;
            }}
          >
            <Image className={styles.image} src={"/images/mine/action-3.png"} />
            <div className={styles.text}>积分</div>
          </div>
          <div
            className={styles.item}
            onClick={async () => {
              Toast("敬请期待");
              return;
            }}
          >
            <Image className={styles.image} src={"/images/mine/action-4.png"} />
            <div className={styles.text}>卡券</div>
          </div>
        </div>
      </div>
      <Sticky>
        <Tabs
          className={styles.tabs}
          active={state.tab}
          onChange={(key) => {
            state.tab = key as AlbumTypeEnum;
          }}
          align="start"
        >
          {tabs.map((item) => (
            <Tabs.TabPane key={item.key} name={item.key} title={item.title} />
          ))}
        </Tabs>
      </Sticky>
      <KeepAlive
        max={20}
        strategy={"PRE"}
        activeName={state.tab.toString()}
        cache={page?.cache}
      >
        {page && (
          <page.component
            type={page.key}
            onClick={(item) => {
              state.target = item;
            }}
          />
        )}
      </KeepAlive>
      <Popup
        className={styles.popup}
        position="bottom"
        closeable
        visible={!!state.target}
        onClose={() => (state.target = null)}
      >
        <div className={styles.container}>
          <div className={styles.title}>
            {state.target?.title} ({state.target?.num ?? 0})
          </div>
          {state.target?.id && (
            <HoldListView
              id={state.target?.id}
              onClick={(item) => {
                state.target = null;
                nav(`/sale/detail/${item.id}`);
              }}
            />
          )}
        </div>
      </Popup>
    </div>
  );
};
